<template>
    <div style="min-height: 200px">
        <div class="table-responsive">
            <table id="trial" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Accounts Type</th>
                        <th>Group level 1</th>
                        <th>Group level 2</th>
                        <th>Group level 3</th>
                        <th>Group level 4</th>
                        <th>Accounts Head</th>
                        <!-- <th class="text-end">Opening</th>
                        <th class="text-end">Movement</th>
                        <th class="text-end">Closing</th> -->
                        <th class="text-end">Debit</th>
                        <th class="text-end">Credit</th>
                    </tr>
                </thead>
                
                <tbody v-if="balances.length">
                    <tr v-for="(item, i) in balances" :key="i">
                        <td>{{ item.account_type }}</td>
                        <td>{{ item.account_level_1_name }}</td>
                        <td>{{ item.account_level_2_name }}</td>
                        <td>{{ item.account_level_3_name }}</td>
                        <td>{{ item.account_level_4_name }}</td>
                        <td>{{ item.account_head_name }}</td>
                        <!-- <td class="text-end">{{ item.opening ? commaFormat(item.opening) : 0 }}</td>
                        <td class="text-end">{{ item.movement ? commaFormat(item.movement) : 0 }}</td>
                        <td class="text-end">{{ item.balance ? commaFormat(item.balance) : 0 }}</td> -->
                        <td class="text-end">{{ item.debit ? commaFormat(item.debit) : 0 }}</td>
                        <td class="text-end">{{ item.credit ? commaFormat(item.credit) : 0 }}</td>
                    </tr>
                    <tr class="bg-blue-light">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>TOTAL</strong></td>
                        <!-- <td class="text-end">{{ commaFormat(getTotal('opening')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('movement')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('balance')) }}</td> -->
                        <td class="text-end">{{ commaFormat(getTotal('debit')) }}</td>
                        <td class="text-end">{{ commaFormat(getTotal('credit')) }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-if="!balances.length" class="mt-5 text-center">No data</p>
        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
export default {
    name: 'TrialBalanceTable',
    props: {
        acName: String,
        balances: {
            default: [],
            type: Array
        }
    },
    methods: {
        getTotal (key) {
            let total = 0;
            this.balances.map(i => {
                if(i[key]) {
                    total += i[key]
                }
            })
            return total
        }, 
    },
    setup() {
        const {commaFormat} = figureFormatter ()

        return {
            commaFormat
        }
    },
}
</script>

<style scoped>
#trial td{
    padding: 2px
}
#trial th{
    padding: 10px 3px;
}
</style>
